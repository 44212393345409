import {FirestoreOrgChargeDetail} from '@/common/firebase/firestore/references/firestoreOrgChargeDetail';
import Logger from '@/common/logger/logger';
import {
  DropInPricePlansScreenState,
  InputPricePlanFixedSettingType,
  InputPricePlanPerDaySettingType,
  InputPricePlanPerUseSettingType,
} from '@/common/redux/screens/state/dropInPricePlansScreenState';
import {RootState} from '@/common/redux/store/rootStateType';
import ArrayUtil from '@/common/utils/arrayUtil';
import {ValidationUtil} from '@/common/utils/validationUtil';
import {ApiDropInPlans} from '@/v2_api/workhub-core/organizations/drop-in-plans/apiDropInPlans';
import {ApiDropInPricePlans} from '@/v2_api/workhub-core/organizations/drop-in-price-plans/apiDropInPricePlans';
import {V2ApiWorkhubCoreOptions} from '@/v2_api/workhub-core/organizations/v2Options/v2_ApiWorkhubCoreOptions';
import {
  PricePlanFixedSetting,
  PricePlanPerDaySetting,
  PricePlanPerUseSetting,
} from '@bitkey-service/v2_core-types/lib/store/organizations/drop-in-price-plans/storeTypesOrgDropInPricePlans';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {batch} from 'react-redux';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const logger = Logger.create('DropInPricePlansScreenState');

const initialState: DropInPricePlansScreenState = {
  initialLoading: true,
  dialog: {
    deletePricePlanDialog: {
      isProcessing: false,
      isOpen: false,
    },
    editBasicInfoDialog: {
      isOpen: false,
      publicPlanName: {validType: 'initial'},
      code: {validType: 'initial'},
      managementName: {validType: 'initial'},
      isProcessing: false,
    },
    editValidPeriodDialog: {
      isOpen: false,
      validFrom: {validType: 'initial'},
      validTo: {validType: 'initial'},
      isProcessing: false,
    },
    editPricePlanDialog: {
      isOpen: false,
      pricePlanType: 'perUse',
      pricePlanSetting: {
        type: 'perUse',
        reservationTime: {
          value: {validType: 'initial'},
          unit: 'm',
        },
        minimumReservationTime: {
          value: {validType: 'initial'},
          unit: 'm',
        },
        maxReservationTime: {
          value: {validType: 'initial'},
          unit: 'm',
        },
        extendingReservationDeadline: {
          value: {validType: 'initial'},
          unit: 'm',
        },
      },
      price: {validType: 'initial'},
      taxDiv: 'in',
      taxRate: {validType: 'initial'},
      reservablePeriod: {
        from: {
          value: {validType: 'initial'},
          unit: 'day',
        },
      },
      cancelPolicy: {validType: 'initial'},
      cancelPolicyEn: {validType: 'initial'},
      cancellationDeadline: {
        value: {validType: 'initial'},
        unit: 'm',
      },
      isProcessing: false,
    },
    editChargeDetailsDialog: {
      isOpen: false,
      id: '',
      code: '',
      name: {validType: 'initial'},
      nameJp: {validType: 'initial'},
      labelId: {validType: 'initial'},
      labelName: {validType: 'initial'},
      description: {validType: 'initial'},
      isProcessing: false,
    },
  },
  persist: {
    dropInPricePlan: {
      id: '',
      publicPlanName: '',
      publicPlanDescription: '',
      code: '',
      name: '',
      validFrom: 0,
      validTo: 0,
      pricePlanType: 'perUse',
      price: 0,
      taxDiv: 'in',
      taxRate: 0.1,
      reservablePeriod: {
        from: {
          value: 0,
          unit: 'day',
        },
      },
      pricePlanSetting: {
        type: 'perUse',
        reservationTime: {
          value: 0,
          unit: 'm',
        },
        minimumReservationTime: {
          value: 0,
          unit: 'm',
        },
        maxReservationTime: {
          value: 0,
          unit: 'm',
        },
      },
      cancelPolicy: '',
      chargeDetailIds: [],
      cancellationDeadline: {
        value: 0,
        unit: 'm',
      },
      registeredBy: {
        peopleId: '',
        name: '',
        imageUrl: '',
        timestamp: 0,
      },
      lastUpdatedBy: {
        peopleId: '',
        name: '',
        imageUrl: '',
        timestamp: 0,
      },
    },
    chargeDetails: [],
    dropInPlans: {
      viewPlans: [],
      searchWord: '',
      lastUpdateFilter: {},
      publishFilter: [],
      sortBy: {
        key: 'code',
        orderBy: 'asc',
      },
    },
    dropInPricePlans: {data: [], result: 'pending'},
    plans: {
      data: [],
      result: 'pending',
    },
  },
};

export const dropInPricePlansScreenSlice = createSlice({
  name: 'dropInPricePlansScreenSlice',
  initialState,
  reducers: {
    clear: () => initialState,
    // 基本情報
    updateEditBasicInfoDialog: (
      state,
      {payload}: PayloadAction<DropInPricePlansScreenState['dialog']['editBasicInfoDialog']['isOpen']>
    ) => {
      state.dialog.editBasicInfoDialog.isOpen = payload;
      if (payload) {
        // 初期値のセット
        state.dialog.editBasicInfoDialog.publicPlanName = {
          value: state.persist.dropInPricePlan?.publicPlanName,
          validType: 'valid',
        };
        state.dialog.editBasicInfoDialog.publicPlanNameEn = {
          value: state.persist.dropInPricePlan?.publicPlanNameEn,
          validType: 'valid',
        };
        state.dialog.editBasicInfoDialog.publicPlanDescription = {
          value: state.persist.dropInPricePlan?.publicPlanDescription,
          validType: 'valid',
        };
        state.dialog.editBasicInfoDialog.publicPlanDescriptionEn = {
          value: state.persist.dropInPricePlan?.publicPlanDescriptionEn,
          validType: 'valid',
        };
        state.dialog.editBasicInfoDialog.code = {value: state.persist.dropInPricePlan?.code, validType: 'valid'};
        state.dialog.editBasicInfoDialog.managementName = {
          value: state.persist.dropInPricePlan?.name,
          validType: 'valid',
        };
      }
      return state;
    },
    updateEditBasicInfoProcessing: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.editBasicInfoDialog.isProcessing = payload;
    },
    updateBasicInfoPublicPlanName: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editBasicInfoDialog']['publicPlanName']>>
    ) => {
      const edit = state.dialog.editBasicInfoDialog;
      edit.publicPlanName = {
        value: payload?.value ?? edit.publicPlanName.value,
        validType: payload?.value ? 'valid' : 'noInput',
      };
    },
    updateBasicInfoPublicPlanNameEn: (
      state,
      {
        payload,
      }: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editBasicInfoDialog']['publicPlanNameEn']>>
    ) => {
      const edit = state.dialog.editBasicInfoDialog;
      edit.publicPlanNameEn = {
        value: payload?.value ?? edit.publicPlanNameEn?.value,
        validType: payload?.value ? 'valid' : 'noInput',
      };
    },
    updateBasicInfoPublicPlanDescription: (
      state,
      {
        payload,
      }: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editBasicInfoDialog']['publicPlanDescription']>>
    ) => {
      const edit = state.dialog.editBasicInfoDialog;
      edit.publicPlanDescription = {
        value: payload?.value ?? edit.publicPlanDescription?.value,
        validType: payload?.value ? 'valid' : 'noInput',
      };
    },
    updateBasicInfoPublicPlanDescriptionEn: (
      state,
      {
        payload,
      }: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editBasicInfoDialog']['publicPlanDescriptionEn']>>
    ) => {
      const edit = state.dialog.editBasicInfoDialog;
      edit.publicPlanDescriptionEn = {
        value: payload?.value ?? edit.publicPlanDescriptionEn?.value,
        validType: payload?.value ? 'valid' : 'noInput',
      };
    },
    updateBasicInfoCode: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editBasicInfoDialog']['code']>>
    ) => {
      const edit = state.dialog.editBasicInfoDialog;
      edit.code = {
        value: payload?.value ?? edit.code.value,
        validType: payload?.validType
          ? payload.validType
          : !payload?.value
            ? 'noInput'
            : ValidationUtil.isValidCode(payload.value)
              ? 'pending'
              : 'invalid',
      };
    },
    updateBasicInfoManagementName: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editBasicInfoDialog']['managementName']>>
    ) => {
      const edit = state.dialog.editBasicInfoDialog;
      edit.managementName = {
        value: payload?.value ?? edit.managementName.value,
        validType: payload?.value ? 'valid' : 'noInput',
      };
    },
    // 利用可能期間
    updateEditValidPeriodDialog: (
      state,
      {payload}: PayloadAction<DropInPricePlansScreenState['dialog']['editValidPeriodDialog']['isOpen']>
    ) => {
      state.dialog.editValidPeriodDialog.isOpen = payload;
      if (payload) {
        // 初期値のセット
        state.dialog.editValidPeriodDialog.validFrom = {
          value: state.persist.dropInPricePlan?.validFrom,
          validType: 'valid',
        };
        state.dialog.editValidPeriodDialog.validTo = {
          value: state.persist.dropInPricePlan?.validTo,
          validType: 'valid',
        };
      }
      return state;
    },
    updateEditValidPeriodProcessing: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.editValidPeriodDialog.isProcessing = payload;
    },
    updateValidPeriodFrom: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editValidPeriodDialog']['validFrom']>>
    ) => {
      const validFrom = payload?.value ? dayjs(payload.value).startOf('day').valueOf() : undefined;
      const validTo = state.dialog.editValidPeriodDialog.validTo?.value;
      const edit = state.dialog.editValidPeriodDialog;
      if ((!!validFrom && !validTo) || (!!validFrom && !!validTo && validFrom <= validTo)) {
        edit.validFrom = {
          value: validFrom ?? edit.validFrom.value,
          validType: 'valid',
        };
      } else {
        edit.validFrom = {
          value: undefined,
          validType: 'invalid',
        };
      }
    },
    updateValidPeriodTo: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editValidPeriodDialog']['validTo']>>
    ) => {
      const validTo = payload?.value ? dayjs(payload.value).endOf('day').valueOf() : undefined;
      const validFrom = state.dialog.editValidPeriodDialog.validFrom?.value;
      const edit = state.dialog.editValidPeriodDialog;
      if ((!!validTo && !validFrom) || (!!validTo && !!validFrom && validTo > validFrom)) {
        edit.validTo = {
          value: validTo ?? edit.validTo?.value,
          validType: 'valid',
        };
      } else {
        edit.validTo = {
          value: validTo,
          validType: 'invalid',
        };
      }
    },

    // 料金プラン
    updateEditPricePlanDialog: (
      state,
      {payload}: PayloadAction<DropInPricePlansScreenState['dialog']['editPricePlanDialog']['isOpen']>
    ) => {
      state.dialog.editPricePlanDialog.isOpen = payload;
      if (payload) {
        // 初期値のセット
        state.dialog.editPricePlanDialog.pricePlanType =
          state.persist.dropInPricePlan?.pricePlanType ?? initialState.dialog.editPricePlanDialog.pricePlanType;
        state.dialog.editPricePlanDialog.price = {
          value: state.persist.dropInPricePlan?.price,
          validType: 'valid',
        };
        state.dialog.editPricePlanDialog.reservablePeriod = {
          from: {
            value: {
              value: state.persist.dropInPricePlan?.reservablePeriod?.from.value,
              validType: 'valid',
            },
            unit:
              state.persist.dropInPricePlan?.reservablePeriod?.from.unit ??
              initialState.dialog.editPricePlanDialog.reservablePeriod.from.unit,
          },
          to: {
            value: {
              value: state.persist.dropInPricePlan?.reservablePeriod?.to?.value ?? 0,
              validType: 'initial',
            },
            unit:
              state.persist.dropInPricePlan?.reservablePeriod?.to?.unit ??
              initialState.dialog.editPricePlanDialog.reservablePeriod.to?.unit ??
              'm',
          },
        };
        state.dialog.editPricePlanDialog.taxDiv =
          state.persist.dropInPricePlan?.taxDiv ?? initialState.dialog.editPricePlanDialog.taxDiv;
        state.dialog.editPricePlanDialog.taxRate = {
          value: state.persist.dropInPricePlan?.taxRate,
          validType: 'valid',
        };
        // TODO: 長い...
        const currentSetting = state.persist.dropInPricePlan?.pricePlanSetting;
        if (currentSetting) {
          switch (currentSetting.type) {
            case 'perUse':
              state.dialog.editPricePlanDialog.pricePlanSetting = {
                ...currentSetting,
                reservationTime: {
                  value: {
                    value: currentSetting.reservationTime.value,
                    validType: 'valid',
                  },
                  unit: currentSetting.reservationTime.unit,
                },
                minimumReservationTime: {
                  value: {
                    value: currentSetting.minimumReservationTime.value,
                    validType: 'valid',
                  },
                  unit: currentSetting.minimumReservationTime.unit,
                },
                maxReservationTime: {
                  value: {
                    value: currentSetting.maxReservationTime.value,
                    validType: 'valid',
                  },
                  unit: currentSetting.maxReservationTime.unit,
                },
                reservationStartTimeUnitMinutes: {
                  value: currentSetting.reservationStartTimeUnitMinutes,
                  validType: 'valid',
                },
                extendingReservationDeadline: {
                  value: {
                    value: currentSetting.extendingReservationDeadline?.value,
                    validType: 'valid',
                  },
                  unit: currentSetting.extendingReservationDeadline?.unit ?? 'm',
                },
              };
              break;
            case 'fixed':
              state.dialog.editPricePlanDialog.pricePlanSetting = {
                ...currentSetting,
                reservationTime: {
                  value: {
                    value: currentSetting.reservationTime.value,
                    validType: 'valid',
                  },
                  unit: currentSetting.reservationTime.unit,
                },
              };
              break;
            case 'perDay':
              state.dialog.editPricePlanDialog.pricePlanSetting = {
                ...currentSetting,
                minimumRequiredReservationDays: {
                  value: currentSetting.minimumRequiredReservationDays,
                  validType: 'valid',
                },
              };
              break;
          }
        } else {
          state.dialog.editPricePlanDialog.pricePlanSetting = initialState.dialog.editPricePlanDialog.pricePlanSetting;
        }
        state.dialog.editPricePlanDialog.cancellationDeadline = {
          value: {
            value: state.persist.dropInPricePlan?.cancellationDeadline?.value,
            validType: 'valid',
          },
          unit:
            state.persist.dropInPricePlan?.cancellationDeadline?.unit ??
            initialState.dialog.editPricePlanDialog.cancellationDeadline.unit,
        };
        state.dialog.editPricePlanDialog.cancelPolicy = {
          value:
            state.persist.dropInPricePlan?.cancelPolicy ?? initialState.dialog.editPricePlanDialog.cancelPolicy?.value,
          validType: 'valid',
        };
        state.dialog.editPricePlanDialog.cancelPolicyEn = {
          value:
            state.persist.dropInPricePlan?.cancelPolicyEn ??
            initialState.dialog.editPricePlanDialog.cancelPolicyEn?.value,
          validType: 'valid',
        };
      }
      return state;
    },
    updateEditPricePlanProcessing: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.editPricePlanDialog.isProcessing = payload;
    },
    updatePricePlanPrice: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editPricePlanDialog']['price']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog;
      edit.price = {
        value: payload?.value,
        validType: payload?.validType ? payload.validType : !payload?.value ? 'noInput' : 'invalid',
      };
    },
    updatePricePlanReservationTimeValue: (
      state,
      {payload}: PayloadAction<Partial<InputPricePlanPerUseSettingType['reservationTime']['value']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog.pricePlanSetting as InputPricePlanPerUseSettingType;
      edit.reservationTime.value = {
        value: payload?.value,
        validType: payload?.validType ? payload.validType : !payload?.value ? 'noInput' : 'invalid',
      };
    },
    updatePricePlanReservationTimeUnit: (
      state,
      {payload}: PayloadAction<Partial<InputPricePlanPerUseSettingType['reservationTime']['unit']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog.pricePlanSetting as InputPricePlanPerUseSettingType;
      edit.reservationTime.unit = payload ?? edit.reservationTime.unit;
    },
    updatePricePlanTaxDiv: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editPricePlanDialog']['taxDiv']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog;
      edit.taxDiv = payload ?? edit.taxDiv;
    },
    updatePricePlanTaxRateValue: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editPricePlanDialog']['taxRate']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog;
      edit.taxRate = {
        value: payload?.value ?? edit.taxRate?.value,
        validType: payload?.validType ? payload.validType : !payload?.value ? 'noInput' : 'invalid',
      };
    },
    updatePricePlanMinimumReservationTimeValue: (
      state,
      {payload}: PayloadAction<Partial<InputPricePlanPerUseSettingType['minimumReservationTime']['value']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog.pricePlanSetting as InputPricePlanPerUseSettingType;
      edit.minimumReservationTime.value = {
        value: payload?.value,
        validType: payload?.validType ? payload.validType : !payload?.value ? 'noInput' : 'invalid',
      };
    },
    updatePricePlanMinimumReservationTimeUnit: (
      state,
      {payload}: PayloadAction<Partial<InputPricePlanPerUseSettingType['minimumReservationTime']['unit']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog.pricePlanSetting as InputPricePlanPerUseSettingType;
      edit.minimumReservationTime.unit = payload ?? edit.minimumReservationTime.unit;
    },
    updatePricePlanMaxReservationTimeValue: (
      state,
      {payload}: PayloadAction<Partial<InputPricePlanPerUseSettingType['maxReservationTime']['value']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog.pricePlanSetting as InputPricePlanPerUseSettingType;
      edit.maxReservationTime.value = {
        value: payload?.value,
        validType: payload?.validType ? payload.validType : !payload?.value ? 'noInput' : 'invalid',
      };
    },
    updatePricePlanMaxReservationTimeUnit: (
      state,
      {payload}: PayloadAction<Partial<InputPricePlanPerUseSettingType['maxReservationTime']['unit']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog.pricePlanSetting as InputPricePlanPerUseSettingType;
      edit.maxReservationTime.unit = payload ?? edit.maxReservationTime.unit;
    },
    updatePricePlanStartReservationTimeValue: (
      state,
      {payload}: PayloadAction<Partial<InputPricePlanPerUseSettingType['reservationStartTimeUnitMinutes']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog.pricePlanSetting as InputPricePlanPerUseSettingType;
      if (payload) {
        edit.reservationStartTimeUnitMinutes = {
          value: payload.value,
          validType: payload.validType ? payload.validType : !payload?.value ? 'noInput' : 'invalid',
        };
      }
    },
    updatePricePlanReservablePeriodFromValue: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<DropInPricePlansScreenState['dialog']['editPricePlanDialog']['reservablePeriod']['from']['value']>
      >
    ) => {
      const edit = state.dialog.editPricePlanDialog;
      edit.reservablePeriod.from.value = {
        value: payload?.value ?? edit.reservablePeriod.from.value.value,
        validType: payload?.validType ? payload.validType : !payload?.value ? 'noInput' : 'invalid',
      };
    },
    updatePricePlanReservablePeriodFromUnit: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<DropInPricePlansScreenState['dialog']['editPricePlanDialog']['reservablePeriod']['from']['unit']>
      >
    ) => {
      const edit = state.dialog.editPricePlanDialog;
      edit.reservablePeriod.from.unit = payload ?? edit.reservablePeriod.from.unit;
    },
    updatePricePlanReservablePeriodToValue: (
      state,
      {
        payload,
      }: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editPricePlanDialog']['reservablePeriod']['to']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog;
      if (payload) {
        edit.reservablePeriod.to = {
          value: payload.value ??
            edit.reservablePeriod.to?.value ?? {
              value: 0,
              validType: 'noInput',
            },
          unit: payload.unit ?? edit.reservablePeriod.to?.unit ?? 'm',
        };
      }
    },
    updatePricePlanReservablePeriodToUnit: (
      state,
      {
        payload,
      }: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editPricePlanDialog']['reservablePeriod']['to']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog;
      if (payload) {
        edit.reservablePeriod.to = {
          unit: payload.unit ?? edit.reservablePeriod.to?.unit ?? 'm',
          value: payload.value ??
            edit.reservablePeriod.to?.value ?? {
              value: 0,
              validType: 'noInput',
            },
        };
      }
    },
    updatePricePlanExtendingReservationDeadlineValue: (
      state,
      {payload}: PayloadAction<Partial<InputPricePlanPerUseSettingType['extendingReservationDeadline']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog.pricePlanSetting as InputPricePlanPerUseSettingType;
      if (payload) {
        edit.extendingReservationDeadline = {
          value: payload.value ??
            edit.extendingReservationDeadline?.value ?? {
              value: 0,
              validType: 'noInput',
            },
          unit: payload.unit ?? edit.extendingReservationDeadline?.unit ?? 'm',
        };
      }
    },
    updatePricePlanExtendingReservationDeadlineUnit: (
      state,
      {payload}: PayloadAction<Partial<InputPricePlanPerUseSettingType['extendingReservationDeadline']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog.pricePlanSetting as InputPricePlanPerUseSettingType;
      if (payload) {
        edit.extendingReservationDeadline = {
          unit: payload.unit ?? edit.extendingReservationDeadline?.unit ?? 'm',
          value: payload.value ??
            edit.extendingReservationDeadline?.value ?? {
              value: 0,
              validType: 'noInput',
            },
        };
      }
    },
    updatePricePlanCancellationDeadlineValue: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<DropInPricePlansScreenState['dialog']['editPricePlanDialog']['cancellationDeadline']['value']>
      >
    ) => {
      const edit = state.dialog.editPricePlanDialog;
      edit.cancellationDeadline.value = {
        value: payload?.value ?? edit.cancellationDeadline.value.value,
        validType: payload?.validType ? payload.validType : !payload?.value ? 'noInput' : 'invalid',
      };
    },
    updatePricePlanCancellationDeadlineUnit: (
      state,
      {
        payload,
      }: PayloadAction<
        Partial<DropInPricePlansScreenState['dialog']['editPricePlanDialog']['cancellationDeadline']['unit']>
      >
    ) => {
      const edit = state.dialog.editPricePlanDialog;
      edit.cancellationDeadline.unit = payload ?? edit.cancellationDeadline.unit;
    },
    updatePricePlanCancelPolicy: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editPricePlanDialog']['cancelPolicy']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog;
      edit.cancelPolicy = {
        value: payload?.value ?? edit.cancelPolicy?.value,
        validType: payload?.value ? 'valid' : 'noInput',
      };
    },
    updatePricePlanCancelPolicyEn: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editPricePlanDialog']['cancelPolicyEn']>>
    ) => {
      const edit = state.dialog.editPricePlanDialog;
      edit.cancelPolicyEn = {
        value: payload?.value ?? edit.cancelPolicyEn?.value,
        validType: payload?.value ? 'valid' : 'noInput',
      };
    },
    // 請求品目
    updateChargeDetailsName: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editChargeDetailsDialog']['name']>>
    ) => {
      const edit = state.dialog.editChargeDetailsDialog;
      edit.name = {
        value: payload?.value ?? edit.name?.value,
        validType: payload?.value ? 'valid' : 'noInput',
      };
      edit.nameJp = {
        value: payload?.value ?? edit.name?.value,
        validType: payload?.value ? 'valid' : 'noInput',
      };
    },
    updateChargeDetailsUnitText: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editChargeDetailsDialog']['unitText']>>
    ) => {
      const edit = state.dialog.editChargeDetailsDialog;
      edit.unitText = {
        value: payload?.value ?? edit.unitText?.value,
        validType: 'valid',
      };
    },
    updateChargeDetailsLabelId: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editChargeDetailsDialog']['unitText']>>
    ) => {
      const edit = state.dialog.editChargeDetailsDialog;
      edit.labelId = {
        value: payload?.value,
        validType: 'valid',
      };
    },
    updateChargeDetailsLabelName: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editChargeDetailsDialog']['unitText']>>
    ) => {
      const edit = state.dialog.editChargeDetailsDialog;
      edit.labelName = {
        value: payload?.value,
        validType: 'valid',
      };
    },
    updateChargeDetailsDescription: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['dialog']['editChargeDetailsDialog']['description']>>
    ) => {
      const edit = state.dialog.editChargeDetailsDialog;
      edit.description = {
        value: payload?.value ?? edit.description?.value,
        validType: 'valid',
      };
    },
    updateEditChargeDetailsDialog: (
      state,
      {payload}: PayloadAction<DropInPricePlansScreenState['dialog']['editChargeDetailsDialog']['isOpen']>
    ) => {
      state.dialog.editChargeDetailsDialog.isOpen = payload;
      if (payload && state.persist.chargeDetails) {
        const [persistChargeDetail] = state.persist.chargeDetails;
        // 初期値のセット
        state.dialog.editChargeDetailsDialog.id = persistChargeDetail.id;
        state.dialog.editChargeDetailsDialog.name = {
          value: persistChargeDetail.name,
          validType: 'valid',
        };
        state.dialog.editChargeDetailsDialog.nameJp = {
          value: persistChargeDetail.nameJp,
          validType: 'valid',
        };
        state.dialog.editChargeDetailsDialog.unitText = {
          value: persistChargeDetail.unitText,
          validType: 'valid',
        };
        state.dialog.editChargeDetailsDialog.labelId = {
          value: persistChargeDetail.labelId ?? '',
          validType: 'valid',
        };
        state.dialog.editChargeDetailsDialog.labelName = {
          value: persistChargeDetail.labelName ?? '',
          validType: 'valid',
        };
        state.dialog.editChargeDetailsDialog.description = {
          value: persistChargeDetail.description,
          validType: 'valid',
        };
      }
      return state;
    },
    updateEditChargeDetailsProcessing: (state, {payload}: PayloadAction<boolean>) => {
      state.dialog.editChargeDetailsDialog.isProcessing = payload;
    },
    // 更新処理をした時に呼び出す
    setPersistDropInPricePlan: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['persist']['dropInPricePlan']>>
    ) => {
      const current = state.persist.dropInPricePlan;
      state.persist.dropInPricePlan = {
        ...current,
        ...payload,
      };
      return state;
    },
    setPersistChargeDetails: (
      state,
      {payload}: PayloadAction<Partial<DropInPricePlansScreenState['persist']['chargeDetails']>>
    ) => {
      const payloadChargeDetails = ArrayUtil.removeUndefined(payload?.map(chargeDetail => chargeDetail) ?? []);
      state.persist.chargeDetails = ArrayUtil.removeDuplicateById([...payloadChargeDetails]);
      return state;
    },
    initializeDropInPricePlan: (
      state,
      {payload}: PayloadAction<DropInPricePlansScreenState['persist']['dropInPricePlan']>
    ) => {
      state.persist.dropInPricePlan = payload;
      return state;
    },
    initializeChargeDetails: (
      state,
      {payload}: PayloadAction<DropInPricePlansScreenState['persist']['chargeDetails']>
    ) => {
      state.persist.chargeDetails = payload;
      return state;
    },
    initializeDropInPlans: (state, {payload}: PayloadAction<DropInPricePlansScreenState['persist']['plans']>) => {
      state.persist.dropInPlans.viewPlans = payload.data;
      state.persist.plans.data = payload.data;
      state.persist.plans.result = payload.result;
    },
    filterViewDropInPlans: (
      state,
      {
        payload,
      }: PayloadAction<{
        searchWord?: string;
        allClear?: boolean;
        lastUpdateFilter?: 'clear' | 'search';
        publishedStatus?: ('publish' | 'private')[];
      }>
    ) => {
      const dropInPlanListUIInstance = state.persist.dropInPlans;
      const persistDropInPlans = state.persist.plans.data;

      if (payload.allClear) {
        state.persist.dropInPlans = {
          ...initialState.persist.dropInPlans,
          viewPlans: persistDropInPlans,
        };
      } else {
        const targetSearchWord = payload.searchWord ?? dropInPlanListUIInstance.searchWord;
        const targetSearchPublishStatus = payload.publishedStatus ?? dropInPlanListUIInstance.publishFilter;
        const targetLastUpdatedAt =
          payload.lastUpdateFilter === 'clear'
            ? {to: undefined, from: undefined}
            : dropInPlanListUIInstance.lastUpdateFilter;

        const filteredPlans = persistDropInPlans.filter(plan => {
          // 文字列フィルター
          const isMatchSearchWord =
            !targetSearchWord ||
            plan.code.toLowerCase().includes(targetSearchWord.toLowerCase()) ||
            plan.name.toLowerCase().includes(targetSearchWord.toLowerCase());

          // 最終更新日フィルター
          const isMatchLastUpdatedAt = !targetLastUpdatedAt
            ? true
            : (!targetLastUpdatedAt.from || plan.lastUpdatedAt >= targetLastUpdatedAt.from) &&
              (!targetLastUpdatedAt.to || plan.lastUpdatedAt <= targetLastUpdatedAt.to);

          // 公開状態フィルター
          const currentPublishStatus = plan.isPublished ? 'publish' : 'private';
          const isMatchPublishStatus =
            !targetSearchPublishStatus.length || targetSearchPublishStatus.includes(currentPublishStatus);

          return isMatchSearchWord && isMatchLastUpdatedAt && isMatchPublishStatus;
        });

        dropInPlanListUIInstance.viewPlans = filteredPlans;
        dropInPlanListUIInstance.lastUpdateFilter = targetLastUpdatedAt ?? {to: undefined, from: undefined};
        dropInPlanListUIInstance.searchWord = targetSearchWord;
        dropInPlanListUIInstance.publishFilter = targetSearchPublishStatus;
      }
    },
    sortViewDropInPlans: (state, {payload}: PayloadAction<{orderBy: 'asc' | 'desc'}>) => {
      const dropInPlanListUIInstance = state.persist.dropInPlans;
      const viewPlans = dropInPlanListUIInstance.viewPlans;
      const currentSortBy = dropInPlanListUIInstance.sortBy;
      const isAsc = payload.orderBy === 'asc';
      dropInPlanListUIInstance.sortBy.orderBy = payload.orderBy;
      dropInPlanListUIInstance.viewPlans = viewPlans.sort((a, b) =>
        a[currentSortBy.key] > b[currentSortBy.key] ? (isAsc ? 1 : -1) : isAsc ? -1 : 1
      );
      state.persist.plans.data = state.persist.plans.data.sort((a, b) =>
        a[currentSortBy.key] > b[currentSortBy.key] ? (isAsc ? 1 : -1) : isAsc ? -1 : 1
      );
    },
    updateLastUpdateFilter: (
      state,
      {payload}: PayloadAction<DropInPricePlansScreenState['persist']['dropInPlans']['lastUpdateFilter']>
    ) => {
      const instance = state.persist.dropInPlans.lastUpdateFilter;
      state.persist.dropInPlans.lastUpdateFilter = {
        from: payload.from ?? instance.from,
        to: payload.to ?? instance.to,
      };
    },
  },
});

export const dropInPricePlansScreenAsyncThunk = {
  // 詳細画面の初期取得
  initializeDropInPricePlanDetailScreen: createAsyncThunk<void, {dropInPricePlanId: string}>(
    'dropInPricePlansScreenAsyncThunk/initialLoad',
    async ({dropInPricePlanId}, {getState, dispatch, rejectWithValue}) => {
      try {
        const state = getState() as RootState;
        const dropInPricePlan = await ApiDropInPricePlans.getDropInPricePlanById(dropInPricePlanId);
        const chargeDetails = await FirestoreOrgChargeDetail.getDataByIds(
          state.organization.id,
          dropInPricePlan.chargeDetailIds
        );
        batch(() => {
          dispatch(
            dropInPricePlansScreenSlice.actions.initializeDropInPricePlan({
              id: dropInPricePlan.id,
              code: dropInPricePlan.code,
              name: dropInPricePlan.name,
              nameJp: dropInPricePlan.nameJp,
              publicPlanName: dropInPricePlan.publicPlanName,
              publicPlanNameEn: dropInPricePlan.publicPlanNameEn,
              publicPlanDescription: dropInPricePlan.publicPlanDescription,
              publicPlanDescriptionEn: dropInPricePlan.publicPlanDescriptionEn,
              price: dropInPricePlan.price,
              taxDiv: dropInPricePlan.taxDiv,
              taxRate: dropInPricePlan.taxRate,
              validFrom: dropInPricePlan.validFrom,
              validTo: dropInPricePlan.validTo,
              pricePlanType: dropInPricePlan.pricePlanType,
              pricePlanSetting: dropInPricePlan.pricePlanSetting,
              reservablePeriod: dropInPricePlan.reservablePeriod,
              cancellationDeadline: dropInPricePlan.cancellationDeadline,
              chargeDetailIds: dropInPricePlan.chargeDetailIds,
              cancelPolicy: dropInPricePlan.cancelPolicy,
              cancelPolicyEn: dropInPricePlan.cancelPolicyEn,
              registeredBy: dropInPricePlan.registeredBy,
              lastUpdatedBy: dropInPricePlan.lastUpdatedBy,
            })
          );
          dispatch(dropInPricePlansScreenSlice.actions.initializeChargeDetails(chargeDetails.map(c => c)));
        });
        return;
      } catch (e) {
        return rejectWithValue(e);
      }
    }
  ),
  fetchDropInPlan: createAsyncThunk<void, {dropInPricePlanId: string}>(
    'dropInPlansScreenAsyncThunk/initialLoad',
    async ({dropInPricePlanId}, {dispatch, rejectWithValue}) => {
      try {
        const dropInPlan = await ApiDropInPlans.getDropInPlansAll();
        const plansMatchedPricePlanId = dropInPlan.filter(p => p.dropInPricePlanIds.includes(dropInPricePlanId));

        dispatch(
          dropInPricePlansScreenSlice.actions.initializeDropInPlans({
            result: 'fulfilled',
            data: plansMatchedPricePlanId.flat().map(plan => ({
              id: plan.id,
              code: plan.code,
              name: plan.name,
              isPublished: plan.publicationStatus === 'published',
              lastUpdatedAt: dayjs(plan.lastUpdatedBy.timestamp).valueOf(),
              pricePlanIds: plan.dropInPricePlanIds,
            })),
          })
        );
        return;
      } catch (e) {
        return rejectWithValue(e);
      }
    }
  ),
  /** 新規作成・編集時コードの重複チェックをするために必要 */
  checkDuplicationPricePlanCode: createAsyncThunk<void>(
    'dropInPlansScreenAsyncThunk/checkDuplicationPricePlanCode',
    async (_, {dispatch, getState, rejectWithValue}) => {
      const state = getState() as RootState;
      const {organizationId} = state.user;
      const dropInPricePlansScreen = state.screens.dropInPricePlansScreen;
      const code = dropInPricePlansScreen.dialog.editBasicInfoDialog.code;

      if (!code.value || !ValidationUtil.isValidCode(code.value)) return;

      // 編集画面でプランに登録してあるcodeと同じであれば重複チェックを行わない
      if (
        dropInPricePlansScreen.dialog.editBasicInfoDialog.code.value ===
        dropInPricePlansScreen.persist.dropInPricePlan?.code
      ) {
        dispatch(dropInPricePlansScreenSlice.actions.updateBasicInfoCode({validType: 'valid'}));
        return;
      }

      try {
        const isDuplicate = await ApiDropInPricePlans.checkDuplicationCode({
          organizationId,
          code: code.value ?? '',
        });
        dispatch(
          dropInPricePlansScreenSlice.actions.updateBasicInfoCode({validType: isDuplicate ? 'duplicate' : 'valid'})
        );
        return;
      } catch (e) {
        return rejectWithValue(e);
      }
    }
  ),
  /** 公開用名称・補足説明・コード・管理名称の更新用 */
  patchBasicInfo: createAsyncThunk<void>(
    'dropInPricePlansScreenAsyncThunk/patchBasicInfo',
    async (_, {getState, dispatch, rejectWithValue}) => {
      const state = getState() as RootState;
      try {
        const editBasicInfoDialog = state.screens.dropInPricePlansScreen.dialog.editBasicInfoDialog;
        const persistPricePlan = state.screens.dropInPricePlansScreen.persist.dropInPricePlan;

        dispatch(dropInPricePlansScreenSlice.actions.updateEditBasicInfoProcessing(true));
        const dropInPricePlanId = persistPricePlan?.id;
        if (!dropInPricePlanId) return;

        await ApiDropInPricePlans.patchDropInPricePlan(dropInPricePlanId, {
          code: editBasicInfoDialog.code.value,
          name: editBasicInfoDialog.managementName.value,
          nameJp: editBasicInfoDialog.managementName.value,
          publicPlanName: editBasicInfoDialog.publicPlanName.value,
          publicPlanNameEn: editBasicInfoDialog.publicPlanNameEn?.value,
          publicPlanDescription: editBasicInfoDialog.publicPlanDescription?.value,
          publicPlanDescriptionEn: editBasicInfoDialog.publicPlanDescriptionEn?.value,
        });
        batch(() => {
          dispatch(
            dropInPricePlansScreenSlice.actions.setPersistDropInPricePlan({
              code: editBasicInfoDialog.code.value,
              name: editBasicInfoDialog.managementName.value,
              publicPlanName: editBasicInfoDialog.publicPlanName.value,
              publicPlanNameEn: editBasicInfoDialog.publicPlanNameEn?.value,
              publicPlanDescription: editBasicInfoDialog.publicPlanDescription?.value,
              publicPlanDescriptionEn: editBasicInfoDialog.publicPlanDescriptionEn?.value,
            })
          );
          dispatch(dropInPricePlansScreenSlice.actions.updateEditBasicInfoDialog(false));
        });
        return;
      } catch (e) {
        logger.error('failed to patchBasicInfo', e);
        return rejectWithValue(e);
      } finally {
        dispatch(dropInPricePlansScreenSlice.actions.updateEditBasicInfoProcessing(false));
      }
    }
  ),
  /** 利用可能期間の更新用 */
  patchValidPeriod: createAsyncThunk<void>(
    'dropInPricePlansScreenAsyncThunk/patchValidPeriod',
    async (_, {getState, dispatch, rejectWithValue}) => {
      const state = getState() as RootState;
      try {
        const editValidPeriodDialog = state.screens.dropInPricePlansScreen.dialog.editValidPeriodDialog;
        const persistPricePlan = state.screens.dropInPricePlansScreen.persist.dropInPricePlan;

        dispatch(dropInPricePlansScreenSlice.actions.updateEditValidPeriodProcessing(true));
        const dropInPricePlanId = persistPricePlan?.id;
        if (!dropInPricePlanId) return;

        await ApiDropInPricePlans.patchDropInPricePlan(dropInPricePlanId, {
          validFrom: Number(editValidPeriodDialog.validFrom.value),
          validTo: editValidPeriodDialog.validTo?.value && Number(editValidPeriodDialog.validTo.value),
        });
        batch(() => {
          dispatch(
            dropInPricePlansScreenSlice.actions.setPersistDropInPricePlan({
              validFrom: Number(editValidPeriodDialog.validFrom.value),
              validTo: editValidPeriodDialog.validTo?.value && Number(editValidPeriodDialog.validTo.value),
            })
          );
          dispatch(dropInPricePlansScreenSlice.actions.updateEditValidPeriodDialog(false));
        });
        return;
      } catch (e) {
        logger.error('failed to patchValidPeriod', e);
        return rejectWithValue(e);
      } finally {
        dispatch(dropInPricePlansScreenSlice.actions.updateEditValidPeriodProcessing(false));
      }
    }
  ),
  /** 料金プランの更新用 */
  patchDropInPricePlan: createAsyncThunk<void>(
    'dropInPricePlansScreenAsyncThunk/patchDropInPricePlan',
    async (_, {getState, dispatch, rejectWithValue}) => {
      const state = getState() as RootState;
      try {
        const editPricePlanDialog = state.screens.dropInPricePlansScreen.dialog.editPricePlanDialog;
        const persistPricePlan = state.screens.dropInPricePlansScreen.persist.dropInPricePlan;

        dispatch(dropInPricePlansScreenSlice.actions.updateEditPricePlanProcessing(true));
        const dropInPricePlanId = persistPricePlan?.id;
        if (!dropInPricePlanId) return;

        await ApiDropInPricePlans.patchDropInPricePlan(dropInPricePlanId, {
          ...persistPricePlan,
          price: Number(editPricePlanDialog.price.value),
          taxDiv: editPricePlanDialog.taxDiv,
          taxRate: editPricePlanDialog.taxRate?.value,
          pricePlanType: editPricePlanDialog.pricePlanType,
          pricePlanSetting: convertPricePlanSetting(
            persistPricePlan.pricePlanSetting,
            editPricePlanDialog.pricePlanSetting
          ),
          reservablePeriod: {
            from: {
              value: Number(editPricePlanDialog.reservablePeriod.from.value.value),
              unit: editPricePlanDialog.reservablePeriod.from.unit,
            },
            to:
              editPricePlanDialog.reservablePeriod.to &&
              editPricePlanDialog.reservablePeriod.to.value.validType !== 'initial'
                ? {
                    value: Number(editPricePlanDialog.reservablePeriod.to.value.value),
                    unit: editPricePlanDialog.reservablePeriod.to?.unit,
                  }
                : undefined,
          },
          cancellationDeadline: {
            value: Number(editPricePlanDialog.cancellationDeadline.value.value),
            unit: editPricePlanDialog.cancellationDeadline.unit,
          },
          cancelPolicy: editPricePlanDialog.cancelPolicy?.value,
          cancelPolicyEn: editPricePlanDialog.cancelPolicyEn?.value,
        });
        batch(() => {
          dispatch(
            dropInPricePlansScreenSlice.actions.setPersistDropInPricePlan({
              ...persistPricePlan,
              price: Number(editPricePlanDialog.price.value),
              taxDiv: editPricePlanDialog.taxDiv,
              taxRate: editPricePlanDialog.taxRate?.value,
              pricePlanType: editPricePlanDialog.pricePlanType,
              pricePlanSetting: convertPricePlanSetting(
                persistPricePlan.pricePlanSetting,
                editPricePlanDialog.pricePlanSetting
              ),
              reservablePeriod: {
                from: {
                  value: Number(editPricePlanDialog.reservablePeriod.from.value.value),
                  unit: editPricePlanDialog.reservablePeriod.from.unit,
                },
                to:
                  editPricePlanDialog.reservablePeriod.to &&
                  editPricePlanDialog.reservablePeriod.to.value.validType !== 'initial'
                    ? {
                        value: Number(editPricePlanDialog.reservablePeriod.to.value.value),
                        unit: editPricePlanDialog.reservablePeriod.to?.unit,
                      }
                    : undefined,
              },
              cancellationDeadline: {
                value: Number(editPricePlanDialog.cancellationDeadline.value.value),
                unit: editPricePlanDialog.cancellationDeadline.unit,
              },
              cancelPolicy: editPricePlanDialog.cancelPolicy?.value,
              cancelPolicyEn: editPricePlanDialog.cancelPolicyEn?.value,
            })
          );
          dispatch(dropInPricePlansScreenSlice.actions.updateEditPricePlanDialog(false));
        });
        return;
      } catch (e) {
        logger.error('failed to patchDropInPricePlan', e);
        return rejectWithValue(e);
      } finally {
        dispatch(dropInPricePlansScreenSlice.actions.updateEditPricePlanProcessing(false));
      }
    }
  ),
  /** 請求データの更新用 */
  patchChargeDetails: createAsyncThunk<void>(
    'dropInPricePlansScreenAsyncThunk/patchChargeDetails',
    async (_, {getState, dispatch, rejectWithValue}) => {
      const state = getState() as RootState;
      try {
        const editChargeDetailsDialog = state.screens.dropInPricePlansScreen.dialog.editChargeDetailsDialog;
        const persistPricePlan = state.screens.dropInPricePlansScreen.persist.dropInPricePlan;
        const [persistChargeDetail] = state.screens.dropInPricePlansScreen.persist.chargeDetails;

        dispatch(dropInPricePlansScreenSlice.actions.updateEditChargeDetailsProcessing(true));
        const dropInPricePlanId = persistPricePlan?.id;
        if (!dropInPricePlanId || !persistChargeDetail) return;

        await V2ApiWorkhubCoreOptions.updateChargeDetail(editChargeDetailsDialog.id, {
          ...persistChargeDetail,
          name: editChargeDetailsDialog.name?.value ?? persistChargeDetail.name,
          unitText: editChargeDetailsDialog.unitText?.value,
          labelId: editChargeDetailsDialog.labelId?.value,
          labelName: editChargeDetailsDialog.labelName?.value,
          description: editChargeDetailsDialog.description?.value,
        });
        batch(() => {
          dispatch(
            dropInPricePlansScreenSlice.actions.setPersistChargeDetails([
              {
                ...persistChargeDetail,
                name: editChargeDetailsDialog.name?.value ?? persistChargeDetail.name,
                unitText: editChargeDetailsDialog.unitText?.value,
                labelId: editChargeDetailsDialog.labelId?.value,
                labelName: editChargeDetailsDialog.labelName?.value,
                description: editChargeDetailsDialog.description?.value,
              },
            ])
          );
          dispatch(dropInPricePlansScreenSlice.actions.updateEditChargeDetailsDialog(false));
        });
        return;
      } catch (e) {
        logger.error('failed to patchChargeDetails', e);
        return rejectWithValue(e);
      } finally {
        dispatch(dropInPricePlansScreenSlice.actions.updateEditChargeDetailsProcessing(false));
      }
    }
  ),
};

const convertPricePlanSetting = (
  currentSetting: PricePlanPerUseSetting | PricePlanFixedSetting | PricePlanPerDaySetting,
  editSetting: InputPricePlanPerUseSettingType | InputPricePlanFixedSettingType | InputPricePlanPerDaySettingType
) => {
  switch (editSetting.type) {
    case 'perUse':
      return {
        ...currentSetting,
        ...editSetting,
        reservationTime: {
          value: Number(editSetting.reservationTime.value.value),
          unit: editSetting.reservationTime.unit,
        },
        minimumReservationTime: {
          value: Number(editSetting.minimumReservationTime.value.value),
          unit: editSetting.minimumReservationTime.unit,
        },
        maxReservationTime: {
          value: Number(editSetting.maxReservationTime.value.value),
          unit: editSetting.maxReservationTime.unit,
        },
        reservationStartTimeUnitMinutes:
          editSetting.reservationStartTimeUnitMinutes?.value &&
          Number(editSetting.reservationStartTimeUnitMinutes.value),
        extendingReservationDeadline: editSetting.extendingReservationDeadline && {
          value: Number(editSetting.extendingReservationDeadline.value.value),
          unit: editSetting.extendingReservationDeadline.unit,
        },
      } as PricePlanPerUseSetting;
    case 'fixed':
      return {
        ...currentSetting,
        ...editSetting,
        reservationTime: {
          value: Number(editSetting.reservationTime.value.value),
          unit: editSetting.reservationTime.unit,
        },
      } as PricePlanFixedSetting;
    case 'perDay':
      return {
        ...currentSetting,
        ...editSetting,
        minimumRequiredReservationDays: Number(editSetting.minimumRequiredReservationDays.value),
      } as PricePlanPerDaySetting;
  }
};
