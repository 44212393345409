import MuiButton from '@mui/material/Button';
import {Theme} from '@mui/material/styles';
import {ThemeProvider} from '@mui/material/styles';
import React, {MouseEventHandler, useMemo, CSSProperties, ForwardRefRenderFunction, forwardRef} from 'react';
import styled from '@emotion/styled';
import {Themes, WDefaultThemeColor, WThemeColor} from '@/common/styles/theme';
import CircularIndicator from '../../components/progress/CircularIndicator';
import type SvgIcon from '@mui/material/SvgIcon';

export type WButtonProps = {
  id?: string;
  label?: string; // アイコンのみのためにoptional。空文字でもいいかと思ったけど
  Icon?: typeof SvgIcon | React.FC; // アイコンのみの場合は下のStartIconとかではなくこちら。
  iconStyles?: React.CSSProperties;
  StartIcon?: typeof SvgIcon | React.FC;
  EndIcon?: typeof SvgIcon | React.FC;
  width?: number | string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  color?: WDefaultThemeColor | WThemeColor;
  theme?: Theme; // カラーパレット外の色つけないといけないとき用。colorと競合した場合はこっち有線。
  variant?: 'text' | 'outlined' | 'contained'; // 初期値はcontained
  disabled?: boolean;
  full?: boolean; // flex=1
  fontSize?: number;
  borderRadius?: number;
  height?: number | string;
  justifycontent?: string;
  backgroundcolor?: string;
  running?: boolean;
  labelMaxWidth?: number;
  // labelのwhite-spaceオプションを指定するときに
  labelWhiteSpace?: string;
  paddingleft?: CSSProperties['paddingLeft'];
  paddingright?: CSSProperties['paddingRight'];
  className?: string;
};
const defaultColor: WDefaultThemeColor[] = ['primary', 'secondary'];

const StartIconWrapper = styled.div`
  margin-right: 8px;
  display: flex;
`;
const EndIconWrapper = styled.div`
  margin-left: 8px;
  display: flex;
`;

const ButtonLabel = styled.div<{maxWidth?: number; whiteSpace?: string}>`
  max-width: ${props => `${props.maxWidth}px`};
  text-overflow: ellipsis;
  white-space: ${props => (props.whiteSpace ? props.whiteSpace : 'nowrap')};
  overflow: hidden;
`;

/**
 * @deprecated @/components/button の TextButton に移行してください
 */
const WButton: ForwardRefRenderFunction<HTMLButtonElement, WButtonProps> = (props, ref) => {
  const {color = 'primary', variant = 'contained'} = props;
  const button = useMemo(
    () => (
      <StyledButton
        ref={ref}
        id={props.id}
        type={'button'}
        label={props.label}
        onClick={props.onClick}
        color={defaultColor.includes(color as WDefaultThemeColor) ? (color as WDefaultThemeColor) : 'primary'}
        variant={variant}
        startIcon={
          props.StartIcon && (
            <StartIconWrapper>
              <props.StartIcon data-testid='wButtonStartIcon' />
            </StartIconWrapper>
          )
        }
        endIcon={
          props.EndIcon && (
            <EndIconWrapper>
              <props.EndIcon />
            </EndIconWrapper>
          )
        }
        disableElevation={true}
        disabled={props.disabled || props.running}
        full={props.full}
        fontSize={props.fontSize}
        borderRadius={props.borderRadius}
        width={props.width}
        height={props.height}
        justifycontent={props.justifycontent}
        backgroundcolor={props.backgroundcolor}
        paddingleft={props.paddingleft}
        paddingright={props.paddingright}
        className={props.className}
      >
        {!!props.Icon && <props.Icon data-testid='wButtonIcon' style={props.iconStyles} />}
        {props.labelMaxWidth || props.labelWhiteSpace ? (
          <ButtonLabel maxWidth={props.labelMaxWidth} whiteSpace={props.labelWhiteSpace}>
            {props.label}
          </ButtonLabel>
        ) : (
          props.label
        )}
        {props.running && <CircularIndicator size={30} />}
      </StyledButton>
    ),
    [props, ref, color, variant]
  );
  return !!props.theme || !defaultColor.includes(color as WDefaultThemeColor) ? (
    <ThemeProvider theme={props.theme ? props.theme : Themes[color]}>{button}</ThemeProvider>
  ) : (
    button
  );
};

export default React.memo(forwardRef(WButton));

const StyledButton = styled(MuiButton)((props: Partial<WButtonProps>) => ({
  minWidth: 0,
  width: props.width ?? undefined,
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: attatchPadding({direction: 'left', props}),
  paddingRight: attatchPadding({direction: 'right', props}),
  height: props.height ?? 32,
  flex: props.full ? 1 : undefined,
  fontSize: props.fontSize ?? undefined,
  borderRadius: props.borderRadius ?? undefined,
  justifyContent: props.justifycontent ?? 'center',
  backgroundColor: props.backgroundcolor ?? undefined,
  '&.MuiButton-contained': {
    border: '1px solid rgba(0, 0, 0, 0)', // これつけておかないとoutlinedに切り替えたときにズレる。box-sizingでいいはずなのになんかきかないので。。。
  },
  '& .MuiButton-endIcon': {
    marginLeft: 'auto',
  },
}));

const attatchPadding = ({direction, props}: {props: WButtonProps; direction: 'left' | 'right'}) => {
  if (direction === 'left' ? props.paddingleft : props.paddingright) {
    return direction === 'left' ? props.paddingleft : props.paddingright;
  }
  if (props.label) {
    return 14;
  }
  return 4;
};
